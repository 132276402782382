<template>
  <div class="home__container theme2" ref="homeWrapper">
    <div class="mask__container" ref="mask">
      <div class="home__swiper-container">
        <div class="home__swiper-inner" v-if="slides.length > 0">
          <swiper class="home-swiper__wrapper" :options="swiperOptions" ref="homeSwiper">
            <swiper-slide class="home-slider__item" v-for="(scroll, index) in slides" :key="index">
              <a class="slide-inner" :href="scroll.info.home_scroll_link" target="_blank">
                <div class="home-slider-item__img-box parallax-container">
                  <img :src="$bowser.mobile ? scroll.info.src_mobile : scroll.info.src_pc" alt="" />
                </div>
              </a>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination hidden" ref="pagination"></div>
          <div v-if="$bowser.mobile" class="home__mobile-info-container">
            <div class="current__title hidden" ref="title">
              <p></p>
            </div>
            <div class="current__name hidden" ref="name">
              <p>{{ currentTitle }} | {{ currentName }}</p>
            </div>
          </div>
          <template v-else>
            <div class="current__title hidden" ref="title">
              <p>{{ currentTitle }}</p>
            </div>
            <div class="current__name hidden" ref="name">
              <p>{{ currentName }}</p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="home__bg" ref="bg"></div>
    <div class="l5 home__ani-logo" ref="aniLogo" @animationend="onAnimationEnd"></div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import GSAP from 'gsap';
import events from '@/utils/eventBus';
import { scroll } from '@/api/data';

const tl = GSAP.timeline();
const interleaveOffset = 0.5;

export default {
  name: 'view-home-2',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    const vue = this;
    return {
      currentTitle: '',
      currentName: '',
      m1: false,
      m2: false,
      slides: [
        // {
        //   src: {
        //     pc: require('@/assets/images/demo1/home/pc/1.jpg'),
        //     mobile: require('@/assets/images/demo1/home/mobile/1.jpg'),
        //   },
        //   type: 'hotel&club',
        //   name: '翳·集',
        //   link: 'http://local-test.gooob.cn/face/demo1.html#/project/52',
        // },
        // {
        //   src: {
        //     pc: require('@/assets/images/demo1/home/pc/2.jpg'),
        //     mobile: require('@/assets/images/demo1/home/mobile/2.jpg'),
        //   },
        //   type: 'Sales Center',
        //   name: '中信泰富济南九庐展示中心',
        //   link: 'http://local-test.gooob.cn/face/demo1.html#/project/42',
        // },
        // {
        //   src: {
        //     pc: require('@/assets/images/demo1/home/pc/3.jpg'),
        //     mobile: require('@/assets/images/demo1/home/mobile/3.jpg'),
        //   },
        //   type: 'Sales Center',
        //   name: '沈阳华润瑞府展示中心',
        //   link: 'http://local-test.gooob.cn/face/demo1.html#/project/39',
        // },
        // {
        //   src: {
        //     pc: require('@/assets/images/demo1/home/pc/4.jpg'),
        //     mobile: require('@/assets/images/demo1/home/mobile/4.jpg'),
        //   },
        //   type: 'Sales Center',
        //   name: '融信.碧桂园大境风华售楼处',
        //   link: 'http://local-test.gooob.cn/face/demo1.html#/project/37',
        // },
        // {
        //   src: {
        //     pc: require('@/assets/images/demo1/home/pc/5.jpg'),
        //     mobile: require('@/assets/images/demo1/home/mobile/5.jpg'),
        //   },
        //   type: 'Sales Center',
        //   name: '正荣青云宸院展示中心',
        //   link: 'http://local-test.gooob.cn/face/demo1.html#/project/40',
        // },
        // {
        //   src: {
        //     pc: require('@/assets/images/demo1/home/pc/6.jpg'),
        //     mobile: require('@/assets/images/demo1/home/mobile/6.jpg'),
        //   },
        //   type: 'Sales Center',
        //   name: '华润置地合肥昆御府营销中心',
        //   link: 'http://local-test.gooob.cn/face/demo1.html#/project/43',
        // },
        // {
        //   src: {
        //     pc: require('@/assets/images/demo1/home/pc/7.jpg'),
        //     mobile: require('@/assets/images/demo1/home/mobile/7.jpg'),
        //   },
        //   type: 'Sales Center',
        //   name: '融信·青澜园展示中心',
        //   link: 'http://local-test.gooob.cn/face/demo1.html#/project/38',
        // },
      ],
      swiperOptions: {
        loop: true,
        loopPreventsSlide: true,
        speed: 1000,
        grabCursor: true,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        pagination: {
          el: '.swiper-pagination',
          type: vue.$bowser.mobile ? 'fraction' : 'bullets',
          formatFractionCurrent(number) {
            return number < 10 ? `0${number}` : number;
          },
          formatFractionTotal(number) {
            return number < 10 ? `0${number}` : number;
          },
        },
        on: {
          init() {
            const { name, type } = vue.$data.slides[0];
            vue.currentName = name;
            vue.currentTitle = type;
          },
          progress() {
            const swiper = this;
            for (let i = 0; i < swiper.slides.length; i += 1) {
              const slideProgress = swiper.slides[i].progress;
              const innerOffset = swiper.width * interleaveOffset;
              const innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(
                '.slide-inner',
              ).style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
            }
          },
          touchStart() {
            const swiper = this;
            for (let i = 0; i < swiper.slides.length; i += 1) {
              swiper.slides[i].style.transition = '';
            }
          },
          setTransition(speed) {
            const swiper = this;
            for (let i = 0; i < swiper.slides.length; i += 1) {
              swiper.slides[i].style.transition = `${speed}ms`;
              swiper.slides[i].querySelector('.slide-inner').style.transition = `${speed}ms`;
            }
          },
          transitionStart() {
            // vue.$refs.name.classList.add('beforeShow');
            const swiper = this;
            vue.$nextTick(() => {
              const $paginationItems = vue.$refs.pagination.querySelectorAll(
                '.swiper-pagination-bullet',
              );
              $paginationItems.forEach((item) => {
                item.classList.remove('actived', 'unfold');
              });
              $paginationItems[swiper.realIndex].classList.add('unfold');
            });
            GSAP.fromTo(
              vue.$refs.name.querySelector('p'),
              {
                y: '0px',
                opacity: 1,
              },
              {
                y: '-30px',
                opacity: 0,
                duration: 0.5,
              },
            );
            GSAP.fromTo(
              vue.$refs.title.querySelector('p'),
              {
                x: '0px',
                opacity: 1,
              },
              {
                x: '30px',
                opacity: 0,
                duration: 0.5,
              },
            );
          },
          transitionEnd() {
            const swiper = this;
            const { home_scroll_name: name, home_scroll_type: type } = vue.$data.slides[
              swiper.realIndex
            ].info;
            vue.currentName = name;
            vue.currentTitle = type;

            vue.$nextTick(() => {
              const activePagination = vue.$refs.pagination.querySelector(
                '.swiper-pagination-bullet-active',
              );
              activePagination.classList.add('actived', 'unfold');
            });

            if (!GSAP.isTweening(vue.$refs.name.querySelector('p')) && swiper.autoplay.running) {
              GSAP.fromTo(
                vue.$refs.name.querySelector('p'),
                {
                  y: '100px',
                  opacity: 0,
                },
                {
                  y: 0,
                  opacity: 1,
                  duration: 0.5,
                },
              );
            }

            if (!GSAP.isTweening(vue.$refs.title.querySelector('p')) && swiper.autoplay.running) {
              GSAP.fromTo(
                vue.$refs.title.querySelector('p'),
                {
                  x: '-30px',
                  opacity: 0,
                },
                {
                  x: 0,
                  opacity: 1,
                  duration: 0.5,
                },
              );
            }
          },
        },
      },
    };
  },
  computed: {
    finallyMove() {
      return this.m1 && this.m2;
    },
  },
  watch: {
    finallyMove: {
      handler(val) {
        if (val) {
          this.$refs.homeSwiper.$swiper.autoplay.start();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getSlides();
    this.$nextTick(() => {
      this.$refs.aniLogo.classList.add('start');
      this.initHeight();
      this.homeAnimation();
      events.$emit('pageMounted');
    });
  },
  destroyed() {
    // document.querySelector('.header__container').classList.remove('show', 'hidden');
  },
  methods: {
    getSlides() {
      const that = this;
      scroll().then((res) => {
        const { lists } = res.data;
        that.slides = lists;
      });
    },
    initHeight() {
      if (this.$bowser.mobile) {
        this.$refs.homeWrapper.style.height = `${document.body.clientHeight}px`;
      }
    },
    onAnimationEnd() {
      tl.play();
      this.$refs.homeSwiper.$swiper.autoplay.stop();
    },
    homeAnimation() {
      const that = this;
      tl.pause();
      const { mask } = that.$refs;
      tl.to(
        mask,
        {
          duration: 1.5,
          ease: 'Expo.easeInOut',
          scale: '1',
        },
        '-=.5',
      );
      tl.eventCallback('onComplete', () => {
        if (document.querySelector('.header__container')) {
          document.querySelector('.header__container').classList.add('show');
        }
        that.$refs.pagination.classList.remove('hidden');
        that.$refs.title.classList.remove('hidden');
        if (!GSAP.isTweening(that.$refs.name.querySelector('p'))) {
          GSAP.set(that.$refs.name.querySelector('p'), {
            y: '100px',
            opacity: 0,
          });
          GSAP.to(that.$refs.name.querySelector('p'), {
            y: 0,
            opacity: 1,
            duration: 0.8,
            onComplete: () => {
              that.m1 = true;
              // this.$refs.homeSwiper.$swiper.autoplay.start();
            },
          });
        }

        if (!GSAP.isTweening(that.$refs.title.querySelector('p'))) {
          GSAP.set(that.$refs.title.querySelector('p'), {
            y: '-30px',
            opacity: 0,
          });
          GSAP.to(that.$refs.title.querySelector('p'), {
            y: 0,
            opacity: 1,
            duration: 0.8,
            onComplete: () => {
              that.m2 = true;
              // this.$refs.homeSwiper.$swiper.autoplay.start();
            },
          });
        }
        // // this.$refs.homeSwiper.$swiper.autoplay.start();
      });
    },
  },
};
</script>

<style></style>
